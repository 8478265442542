.untitledDesign341 {
  width: 1440px;
  height: 823px;
  position: relative;
  object-fit: cover;
  display: none;
  max-width: 100%;
  z-index: 0;
}
.community {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0.87deg, transparent 33.1%, #000 55.69%);
  z-index: 1;
}




.redefiningLeatherCraftsmansh,
.yourJourneyBegins {
  margin: 20;
  position: relative;
  text-transform: uppercase;
  font-family: inherit;
  display: inline-block;
  z-index: 2;
}
.redefiningLeatherCraftsmansh {
  width: 700px;
  height: 118px;
  font-size: inherit;
  letter-spacing: 0.1em;
  font-weight: 700;
}
.yourJourneyBegins {
  height: 37px;
  font-size: var(--font-size-3xl);
  letter-spacing: 0.02em;
  line-height: 27px;
  font-weight: 500;
  text-align: left;
  flex-shrink: 0;
  max-width: 100%;
}
.maskGroup,
.ourService,
.untitledDesign341Parent {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.ourService {
  flex-direction: column;
  align-items: center;
  gap: 15px 0;
}
.maskGroup,
.untitledDesign341Parent {
  box-sizing: border-box;
}
.untitledDesign341Parent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-17xl) var(--padding-17xl) var(--padding-424xl);
  position: relative;
  gap: 49px 0;
  background-image: url("../public/untitled-design-34-1@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.maskGroup {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 var(--padding-81xl);
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--color-white);
  font-family: var(--text-button);
}

@media screen and (max-width: 1050px) {
  /* .reduceCarbonFootprint {
    gap: 0 289px;
  } */
  .redefiningLeatherCraftsmansh {
    font-size: var(--font-size-19xl);
  }
  .untitledDesign341Parent {
    padding-top: var(--padding-4xl);
    padding-bottom: var(--padding-269xl);
    box-sizing: border-box;
  }
  .maskGroup {
    padding-bottom: var(--padding-46xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .untitledDesign341Parent {
    gap: 49px 0;
  }
}
@media screen and (max-width: 450px) {
  .reduceCarbonFootprint {
    gap: 0 289px;
  }
  .redefiningLeatherCraftsmansh {
    font-size: var(--font-size-10xl);
  }
  .yourJourneyBegins {
    font-size: var(--font-size-lg);
    line-height: 22px;
  }
  .untitledDesign341Parent {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-168xl);
    box-sizing: border-box;
  }
  .maskGroup {
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
/* .answer {
  display: none;
} */

