/* New Article List starts */
.newArticleListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0rem;
  padding-left: 40px;
  padding-right: 40px;
}
.newArticleContent {
  width: 200px;
  height: 44px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  word-wrap: break-word;
}
.newArticleListHeaderTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.newArticleListHeaderText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
/* .newArticleCardListContainer {
  margin: 20px 0px;
  width: 352px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0 0.25rem;
} */

.newArticleCardListContainer {
  margin: 20px 0px;
  width: 100%; /* Adjusted for responsiveness */
  display: flex;
  flex-direction: column;
  gap: 20px; /* Changed from row-gap to gap */
  padding: 0 0.25rem;
}

.newArticleCard {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  cursor: pointer;
}
.newArticleImg {
  width: 90px;
  height: 90px;
}
.btnPrimary {
  width: 100%;
  background: linear-gradient(90deg, #a77041 0%, #673821 100%);
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 10px !important;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #ffffff;
}
/* New Article List ends */
.articleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0rem;
  width: 100%;
  padding: 3% 2%;
}
.articleCardContainer {
  width: 100%;
}
.articleCard {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 5%;
  height: 225px;
  background: #ffffff;
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  cursor: pointer;
  padding: 1rem;
}
.articleCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.articleFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.articleCardImgContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.nameContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #262626;
}
.timeContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #727272;
}
.articleContent {
  /* width: 700px; */
  height: 64px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.likesCount {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.commentsCount {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.shareCount {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.footerImgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  background-color: #f5f5f5;
  border-radius: 50%;
  border: 3px solid #fff;
  padding: 0.5rem;
  overflow: hidden;
}
.paginationBtn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 180px;
  height: 53px;
  border: 1px solid #000000;
  border-radius: 40px;
}
.paginationBtnText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
}
.paginationPageBtnContainer {
  background: rgba(103, 56, 33, 0.2);
  border-radius: 22px;
}
.paginationPageBtn {
  width: 62px;
  height: 53px;
  /* background: #673821; */
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active {
  background: #673821;
  color: #fff;
}

@media (min-width: 320px) and (max-width: 990px) {
  .newArticleListContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 320px) and (max-width: 460px) {
  .articleFooter {
    flex-direction: column;
    /* justify-self:flex-start; */
    align-items: flex-start;
  }
  .likesContainer {
    /* padding-bottom: 15px; */
  }
  .paginationBtn {
    /* width: 45px !important; */
    width: 100% !important;
    height: 30px !important;
    font-size: 0.8rem;
    padding: 0 0.5rem;
  }
  .paginationContainer{
    padding: 0 !important;
    width: 100% !important;
  }
  .paginationPageBtn{
    width: 30px;
    height: 30px;
  }
  .paginationBtnIcon{
    width: 20px;
    height: 20px;
  }
  .paginationBtnText{
    font-size: 0.8rem;
  }
  .newArticleCard {
    padding: 0 0;
  }
  .articleCardHeader {
    justify-content: space-between;
  }
  .articleCard{
    padding: 0.25rem;
  }
  .newArticleListContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
  .paginationPageBtn {
    width: 35px;
  }
  .newArticleCard {
    justify-content: space-between !important;
  }
  .newArticleListHeaderText {
    font-size: 1.2rem;
  }
  .newArticleImg {
    width: 70px;
    height: 70px;
    object-fit: contain;
  }
  .newArticleContent {
    font-size: 1rem;
    width: 100%;
  }
  .btnPrimary {
    font-size: 1rem;
  }
  .articleContainer{
    padding: 0 !important;
  }
  .nameContainer{
    font-size: 1rem;
  }
  .timeContainer{
    font-size: 0.575rem;
  }
  .articleContent{
    font-size: 0.8rem;
  
  }
}

@media (max-width: 768px) {
  .articleListContainer {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
  .paginationBtn {
    width: 100%;
    margin: auto;
  }

  .newArticleListContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
  .paginationPageBtn {
    width: 30px;
  }
  .newArticleCard {
    justify-content: space-around;
  }
}
