.howItWorks {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}
.underlineIcon {
  align-self: stretch;
  height: 8px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.heading,
.headingWrapper {
  display: flex;
  justify-content: flex-start;
}
.heading {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 10px 0;
}
.headingWrapper {
  width: 341px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-5xl) 0 0;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-19xl);
  color: var(--color-black);
  font-family: var(--text-button);
}
@media screen and (max-width: 1050px) {
  .howItWorks {
    font-size: var(--font-size-11xl);
  }
}
@media screen and (max-width: 450px) {
  .howItWorks {
    font-size: var(--font-size-4xl);
  }
}
