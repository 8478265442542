@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Free:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --text-button: Montserrat;
  --font-ubuntu: Ubuntu;
  --font-font-awesome-5-free: "Font Awesome 5 Free";
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --text-button-size: 20px;
  --font-size-29xl: 48px;
  --font-size-19xl: 38px;
  --font-size-10xl: 29px;
  --font-size-3xl: 22px;
  --font-size-lg: 18px;
  --font-size-11xl: 30px;
  --font-size-4xl: 23px;
  --font-size-sm: 14px;

  /* Colors */
  --color-white: #fff;
  --color-saddlebrown-100: #673821;
  --color-saddlebrown-200: rgba(103, 56, 33, 0.6);
  --color-saddlebrown-300: rgba(103, 56, 33, 0.15);
  --lotion-white: #fafbfa;
  --color-gray-100: #7e7e7e;
  --color-gray-200: #3c271e;
  --black: #111;
  --color-gray-300: #070707;
  --color-gray-400: rgba(17, 17, 17, 0.32);
  --color-gray-500: rgba(255, 255, 255, 0.5);
  --color-gainsboro: #e6e6e6;
  --color-dimgray-100: #636363;
  --color-dimgray-200: #535353;
  --color-black: #000;
  --color-whitesmoke: rgba(241, 241, 241, 0.1);

  /* Gaps */
  --gap-24xl: 43px;
  --gap-xl: 20px;
  --gap-18xl: 37px;
  --gap-0: 0px;
  --gap-12xl: 31px;
  --gap-5xl: 24px;
  --gap-16xl: 35px;
  --gap-11xl: 30px;
  --gap-13xl: 32px;
  --gap-3xs: 10px;
  --gap-8xl: 27px;
  --gap-46xl: 65px;
  --gap-23xl: 42px;
  --gap-2xs: 11px;
  --gap-30xl: 49px;
  --gap-mini: 15px;

  /* Paddings */
  --padding-3xl: 22px;
  --padding-53xl: 72px;
  --padding-12xs: 1px;
  --padding-28xl: 47px;
  --padding-xl: 20px;
  --padding-4xs: 9px;
  --padding-496xl: 515px;
  --padding-5xs: 8px;
  --padding-238xl: 257px;
  --padding-109xl: 128px;
  --padding-96xl: 115px;
  --padding-94xl: 113px;
  --padding-56xl: 75px;
  --padding-37xl: 56px;
  --padding-3xs: 10px;
  --padding-332xl: 351px;
  --padding-156xl: 175px;
  --padding-21xl: 40px;
  --padding-3xs-2: 9.2px;
  --padding-lgi-6: 19.6px;
  --padding-17xl: 36px;
  --padding-5xl: 24px;
  --padding-35xl: 54px;
  --padding-16xl: 35px;
  --padding-33xl: 52px;
  --padding-39xl: 58px;
  --padding-97xl: 116px;
  --padding-15xl: 34px;
  --padding-45xl: 64px;
  --padding-19xl: 38px;
  --padding-sm: 14px;
  --padding-mini: 15px;
  --padding-10xs: 3px;
  --padding-64xl: 83px;
  --padding-smi: 13px;
  --padding-mid: 17px;
  --padding-2xs: 11px;
  --padding-22xl: 41px;
  --padding-xs: 12px;
  --padding-8xs: 5px;
  --padding-6xs: 7px;
  --padding-81xl: 100px;
  --padding-46xl: 65px;
  --padding-23xl: 42px;
  --padding-424xl: 443px;
  --padding-4xl: 23px;
  --padding-269xl: 288px;
  --padding-168xl: 187px;

  /* Border radiuses */
  --br-81xl: 100px;
  --br-481xl: 500px;
  --br-9xs: 4px;
  --br-31xl: 50px;
  --br-3xl: 22px;
  --br-51xl: 70px;
  --br-101xl: 120px;
  --br-8xs: 5px;
  --br-3xs: 10px;
}
