/* Default styles */
.priceModelContainer {
    width: 940px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap-xl);
    text-align: left;
    /* font-size: var(--font-size-19xl); */
    color: var(--color-black);
    font-family: var(--text-button);
    margin-bottom: 60px;
  
  }

  .subscription{
     display: flex;
     justify-content: center;
     align-items: center;
     margin-bottom: 50px;
  }
  
  
  .buttonsContainer {
    display: flex;
    justify-content: center;
    color: var(--color-black);
    font-family: var(--text-button);
    background-color: rgba(67, 21, 0, 0.6);
    border-radius: 40px;
    margin-bottom: 70px;

  }
  
  .priceButton {
    background-color: transparent;
    border: none;
    padding: 10px 36px;
    margin: 5px;
    cursor: pointer;
    border-radius: 40px;
  }
  
  .priceButton.active {
    background-color: white;
  }
  .contentline{
    width: 270px;
   font-weight: Weight
   500;
   font-size: 16px;
  }
  .contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 370.28px;
height: 523.31px;
border-radius: 40px;
box-shadow: 0px 5px 10px 0px #00000040;
  }
  .rightsidev{
    width: 410px;
  }
  .transections{
    font-weight: 700;
    font-size: small;
    width: 230px;
  }
  .continue{
    display: flex;
    margin: auto;
    cursor: pointer;
    border: 0;
    padding: 10px;
    background-color: transparent;
    border-radius: var(--br-481xl);
    background: linear-gradient(129.11deg, #a77041, #673821);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    width: 184px;
    /* margin-top: 50px; */
  }
  .monthlyPrice{
    font-family: Montserrat;
/* font-size: 55px; */
font-weight: 600;
text-align: left;
padding-bottom: 30px;
padding-top: 20px;
  }

  .coinpng{
    width: 30.83px;
height: 30.83px;
top: 3474.09px;
left: 268.53px;
gap: 0px;
opacity: 0px;


  }
  .buttoncontinue{
    position: relative;
    font-size: var(--text-button-size);
    letter-spacing: 0.01em;
    font-family: var(--text-button);
    color: var(--color-white);
    background: linear-gradient(129.11deg, #a77041, #673821);
    border: none;
   
  }

  
  .content {
    display: none;
  }
  
  .content.active {
    display: block;
  }
  
  .picContainer {
    margin-top: 20px;
  }
  
  .pic {
    height: 379px;
    width: 506px;
    border-radius: 0 var(--br-51xl) 0 var(--br-101xl);
    border: 5px solid var(--color-saddlebrown-100);
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    min-width: 506px;
    max-width: 100%;
  }
  .ticks{
    color: var(--color-saddlebrown-100);;
  }
  
  /* Media queries for responsiveness */
  @media only screen and (max-width: 768px) {
    .buttonsContainer {
      flex-wrap: wrap;
    }
  
    .priceButton {
      margin: 5px;
    }
    .priceModelContainer{
        flex-direction: column;
    }
    

  }
  