.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 42px 0;
  min-width: 529px;
  max-width: 100%;
}
.bg {
  position: absolute;
  top: -66px;
  left: -197px;
  border-radius: var(--br-3xl);
  background-color: var(--color-gray-400);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 684px;
  height: 452px;
}
.closeUpOnEngravingArtTool {
  position: absolute;
  top: -5px;
  left: -95px;
  width: 481px;
  height: 320px;
  object-fit: cover;
  z-index: 1;
}
.photo {
  height: 320px;
  flex: 1;
  position: relative;
  border-radius: 0 var(--br-51xl) 0 var(--br-101xl);
  border: 5px solid var(--color-saddlebrown-100);
  box-sizing: border-box;
  overflow: hidden;
  min-width: 188px;
}
.bg1 {
  position: absolute;
  top: -66px;
  left: -197px;
  border-radius: var(--br-3xl);
  background-color: var(--color-gray-400);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 684px;
  height: 452px;
}
.maleDesignerAndLeatherTail {
  position: absolute;
  top: -7px;
  left: -151px;
  width: 497px;
  height: 331px;
  object-fit: cover;
  z-index: 1;
}
.photo1 {
  height: 320px;
  flex: 1;
  position: relative;
  border-radius: 0 var(--br-51xl) 0 var(--br-101xl);
  border: 5px solid var(--color-saddlebrown-100);
  box-sizing: border-box;
  overflow: hidden;
  min-width: 188px;
}
.group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 40px;
}
.bg2 {
  position: absolute;
  top: 16px;
  left: 0;
  border-radius: var(--br-3xl);
  background-color: var(--color-gray-400);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 684px;
  height: 452px;
}
.manInAStudioCreatesLeathe {
  position: absolute;
  top: 0;
  left: 29px;
  width: 623px;
  height: 410px;
  object-fit: cover;
  z-index: 1;
}
.designHub {
  margin-top: -127px;
  margin-bottom: -111px;
  height: 468px;
  width: 684px;
  position: relative;
  max-width: 110%;
  flex-shrink: 0;
}
.heading,
.photo2,
.text1 {
  display: flex;
  max-width: 100%;
}
.photo2 {
  align-self: stretch;
  height: 230px;
  border-radius: 0 var(--br-51xl) 0 var(--br-101xl);
  border: 5px solid var(--color-saddlebrown-100);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.heading,
.text1 {
  align-items: flex-start;
  justify-content: flex-start;
}
.text1 {
  flex: 1;
  flex-direction: column;
  gap: 30px 0;
  min-width: 403px;
}
.heading {
  align-self: stretch;
  flex-direction: row;
  gap: 0 46px;
  text-align: left;
  font-size: var(--font-size-19xl);
  color: var(--color-black);
  font-family: var(--text-button);
}
@media screen and (max-width: 1050px) {
  .text {
    flex: 1;
  }
  .heading {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .text {
    gap: 42px 0;
    min-width: 100%;
  }
  .group {
    flex-wrap: wrap;
    gap: 0 40px;
  }
  .text1 {
    min-width: 100%;
  }
  .heading {
    gap: 0 46px;
  }
}
