


.newPostContainer {
  /* Group 1000002986 */

  width: 790px;
  height: 465px;
  padding: 20px;
}
.newPostContainerHeader {
  display: flex;
  justify-content: space-between;
}
.newPostHeaderText {
  /* Create New Post */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #262626;
}
.postContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  padding: 1rem;
}
.postTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical too box height */
  margin: 1rem 0 2rem 0;
  color: #727272;
}
.postTextContainer {
  border: none;
  width: 100%;
  height: 200px;
  color: #727272;
  margin-bottom: 2rem;
}
.postFooterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.postFooterIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}
.postFooterIcon {
  width: 20px;
  height: 20px;
  color: #000;
}
.postButton {
  width: 110px;
  height: 40px;
  background: linear-gradient(180deg, #a77041 0%, #673821 100%);
  border-radius: 49px;
    border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
}
@media (min-width: 320px) and (max-width:990px){

}

@media (min-width: 320px) and (max-width:768px){
.newPostContainer{
  padding: 15px 0px 0px 0px;
}

}
@media (min-width: 320px) and (max-width:420px){
  .CreatePostContainer{
    flex-direction: column;
  }
  .newPostContainer{
    width: 100%;
  }

}