.exploreOurUnique {
  height: 53px;
  flex: 1;
  position: relative;
  line-height: 27px;
  display: inline-block;
  max-width: 100%;
}
.ourService {
  width: 690px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-8xs);
  box-sizing: border-box;
  max-width: 100%;
}
.bg {
  position: absolute;
  top: calc(50% - 226px);
  left: calc(50% - 342px);
  border-radius: var(--br-3xl);
  background-color: var(--black);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 684px;
  height: 452px;
  opacity: 0.32;
  z-index: 2;
}
.tailorCuttingLeatherWithSc {
  /* position: absolute;
  top: -26px;
  left: -9px;
  width: 359px;
  height: 241.4px;
  object-fit: cover;
  z-index: 1; */
  width: 100%;
}
.frameChild,
.photo {
  align-self: stretch;
  position: relative;
}
.photo {
  height: 190px;
  border-radius: 0 var(--br-31xl) 0 0;
  /* border: 5px solid var(--color-whitesmoke); */
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.frameChild {
  height: 198px;
  background-color: var(--color-saddlebrown-100);
  display: none;
}
.sustainablePractices {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}
.sustainablePracticesWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 0 var(--padding-mid); */
}
.ourPlatformPrioritizes {
  /* width: 323px; */
  position: relative;
  font-size: var(--font-size-base);
  line-height: 27px;
  font-weight: 500;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}
.rectangleParent {
  align-self: stretch;
  background-color: var(--color-saddlebrown-100);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  /* padding: var(--padding-smi) 0 var(--padding-sm); */
  padding: var(--padding-smi) var(--padding-2xs) var(--padding-22xl) var(--padding-mid);
  box-sizing: border-box;
  gap: 10px 0;
  max-width: 100%;
}
.connectPlatform,
.socialMediaLogos {
  align-items: flex-start;
  max-width: 100%;
}
.socialMediaLogos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.connectPlatform {
  align-self: stretch;
  display: grid;
  flex-direction: row;
  justify-content: space-between;
  grid-template-columns: repeat(3, minmax(255px, 1fr));
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-white);
}
.communityQr,
.plantTypes {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.communityQr {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 27px 0;
}
.plantTypes {
  width: 1238px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-3xl) var(--padding-64xl) var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--text-button-size);
  color: var(--color-dimgray-100);
  font-family: var(--text-button);
}
@media screen and (max-width: 1050px) {
  .connectPlatform {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(255px, 442px));
  }
}
@media screen and (max-width: 750px) {
  .connectPlatform {
    grid-template-columns: minmax(255px, 1fr);
  }
  .plantTypes {
    padding-bottom: var(--padding-35xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .exploreOurUnique {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
  .sustainablePractices {
    font-size: var(--font-size-lg);
  }
}
