* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
:focus-visible {
  outline: none;
}
ul {
  padding: 0;
}

/* body {
 color: #333;
 font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
} */
.justify-content-center {
  justify-content: center;
}

/* find-pro css */
.hero {
  background-image: url("../images/family.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 788px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-position: center;
  align-items: center;
}
.hero-articlelist,
.hero-createpost {
  background-image: url("../images/Front_view_group_of_young_people_holding_hands.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 788px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-position: center;
  align-items: center;
}
.navigationbar {
  margin-top: 47px;
  border-radius: 10px;
}
.header-box {
  border-radius: 10px;
}
/* 
.navbar {
    min-height: 90px; 
    max-width: 1260px; 

    background-color: #FFFFFF;
    margin-top: 55px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 9;
    margin-left: auto; 
    margin-right: auto; 
    padding-left: 15px; 
    padding-right: 15px; 
}

@media screen and (max-width: 1200px) {
    .navbar {
        flex-direction: column; 
        height :35%;
     
        
    }
}
@media screen and (min-width: 1200px) {
    .navbar {
        width: 1260px; 
        
    }
}

.logo
{
background-image:url("../images/logo.png");
height:57px;
width:157px;
}
.findpro-dropdown
{
    width:fit-content;
    

}
.dropdown-content
{
    display:flex;
    flex-direction:column;
    background-color:#FFFFFF;
    position: absolute;
    height:57px;
    width: 197px;
    border:#C0C0C0 solid 1px;
    margin-top:8px;
    
    

}
.dropbtn
{
background-color:#FFFFFF;
border:none;
box-shadow: 2px 3px #C0C0C0;
height:25px;
width:fit-content;
display:flex;
gap:10px;
align-items:center;
justify-content: center; 
font-family: 'Montserrat' sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.38px;
color: #000000;
border-radius: 8px;

}


.find-option
{
    padding-top:10px;
    padding-bottom:10px;
    background-color:#FFFFFF;
    z-index: 9px;
    border-bottom: 1px solid #C0C0C0;
    

}

.search-area
{
    
    height:48px;
    width:315px;
    border-radius: 8px;
    border: none;
    border: 1px solid #C0C0C0
    
}
.community-link
{
   
    color:black;
    text-decoration:none;
    width: 123px;
    height: 24px;
    font-family: 'Montserrat' sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
 
}

.userlogo
{
    background-image: url('../images/user-logo.png');
    height:32px;
    width:32px;

}
.user-name
{
    
    height:24px;
    width:123px;
    color:#C0c0c0;
    background-color: #FFFFFF;
    border:none;
    text-align: left;
}
.user-account
{
    display:flex;
    align-items: center;
    
    
} */
.shade {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 55%,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0) 100%
  );

  background-size: cover;
  height: 600px;
  width: 100dvw;
  margin: 0;
  position: absolute;
}

.hero-text {
  color: #ffffff;

  z-index: 9;
  margin-top: 45px;
  /* height:fit-content; */
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.line2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500px;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top: 20px;
  max-width: 600px;
  width: 100%;
  height: 54px;
}

.line1 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 70.4px;
  letter-spacing: -0.05em;
  text-align: center;
}
@media screen and (max-width: 1000px) {
  .line1 {
    font-size: 45px;
    font-weight: 400px;
    line-height: 40px;
  }
  .line2 {
    font-size: 14px;
    font-weight: 300px;
    line-height: 20px;
  }
}

.main {
  /* height:950px; */

  height: content-fit;
  display: flex;
  /* flex-direction: column; */
}
@media screen and (max-width: 600px) {
  .main {
    flex-direction: column;
    margin-left: 10px;
  }
}
@media screen and (max-width: 1600px) {
  .main {
    margin-left: 10px;
  }
}
/* .filter-content
{
    display:flex;
} */

/* .container-box
{
    display: flex;
    flex-direction: column;
    height:fit-content;
    justify-content: space-between;
}
@media screen and (max-width: 1200px) {
    .container-box {
        
        height:auto;
    }
} */
.filters {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 281px;
  margin-top: 50px;
  margin-left: 120px;
}
@media screen and (max-width: 1000px) {
  .filters {
    /* flex-direction: row;
        justify-content:space-between;
        align-items: center;
        margin: 20px auto;
        min-width: 281px;
        padding-left: 10px;
        flex-wrap: wrap;
        width:100dvw; */
    margin-left: 0px;
  }
}

.button-lable {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  color: #464646;
}
/* @media screen and (max-width: 768px) {
    .btn-nolable {
     margin-right:20px;
     display:flex;
    }
} */
/* @media screen and (max-width: 768px) {
    .button-lable {
    flex-direction: row;
     
    
    }
} */
.button-lable {
  position: relative;
}

.dropbtn1 {
  background-color: #ffffff;
  border: 1px solid #ebedf4;
  color: #a3a3a3;
  min-height: 57px;
  height: 100%;
  padding: 0px 0px 1px 0px;
  gap: 0px;
  border: 0px 0px 1px 0px;
  opacity: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  max-width: 281px;
  width: 100%;
  /* display: flex;
    justify-content: space-around; */
}
.dropbtn2 {
  background-color: #ffffff;
  border: 1px solid #ebedf4;
  color: #a3a3a3;
  min-height: 57px;
  margin-bottom: 5px;
  padding: 0px 20px;

  padding: 0px 0px 1px 0px;
  gap: 0px;
  border: 0px 0px 1px 0px;
  opacity: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  max-width: 281px;
  width: 100%;
}
.dropdown-content {
  height: fit-content;
  border: none;
}
.dropbtn-lable {
  color: #464646;
  margin-top: 5px;
}
.dropbtn3,
.dropbtn4,
.dropbtn5 {
  border-bottom: 1px solid #e6e6e6;
  background: #ffffff;
  height: 57px;
  /* margin-left:4px; */
  gap: 0px;
  border-right: none;
  border-left: none;
  border-top: none;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: flex-end;
  max-width: 281px;
  width: 100%;
}

.filter-dropdown {
  display: flex;
  flex-direction: column;
  max-width: 281px;
  width: 100%;
  position: absolute;
}
/* @media screen and (max-width: 768px) {
    .filter-dropdown{
    width:50%;
    }
} */

.main-page {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  max-width: 897px;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .main-page {
    margin-left: 0px;
  }
}

.filter-applied {
  display: flex;
  justify-content: space-between;
  max-width: 897px;
  width: 100%;
  margin-top: 50px;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .filter-applied {
    margin-top: 20px;
  }
}

.category {
  border: 1px solid #673821;
  background: #673821;
  width: 142.31px;
  height: 30px;
  left: 424px;

  gap: 0px;
  border-radius: 15px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
}
.searchbyname {
  border: 1px solid;
  width: 235px;
  height: 40px;
  border-radius: 3px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border-color: #eeeeee;
}
@media screen and (max-width: 768px) {
  .filter-applied {
    width: 90%;
  }
}
.search-bar::placeholder {
  font-family: "Montserrat", sans-serif;
  color: #777777;

  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
}

.profiles {
  max-width: 897px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
}

.profile1,
.profile2,
.profile3 {
  height: auto;
  border-bottom: 1px solid #e6e6e6;
  margin-top: 25px;
  display: flex;
  max-width: 897px;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .profile1,
  .profile2,
  .profile3 {
    display: flex;
    flex-direction: column;
  }
}

.picture1 {
  max-width: 207px;
  width: 100%;
  height: 207px;
  background-size: cover;

  background-image: url("../images/Close\ up\ of\ a\ cobbler\ working\ with\ leather\ textile.png");
}
.picture2 {
  max-width: 207px;
  width: 100%;
  height: 207px;
  background-size: cover;

  background-image: url("../images/Man\ in\ a\ studio\ creates\ leather\ ware\ \(1\).png");
}
.picture3 {
  max-width: 207px;
  height: 207px;

  width: 100%;
  background-size: cover;

  background-image: url("../images/Man\ in\ a\ studio\ creates\ leather\ ware.png");
}
@media screen and (max-width: 1200px) {
  .picture1,
  .picture2,
  .picture3 {
    /* width: 80%;
        height:80%; */
    /* margin-left:10px; */
  }
}

.info {
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  max-width: 668px;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .info {
    margin-left: 0px;

    /* width:80%; */
  }
}

/* @media screen and (max-width: 1200px) {
    .info {

     
     
    }
} */

.name-email {
  height: 40px;
  /* width:668px; */
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .name-email {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    justify-content: space-around;
    /* height:fit-content; */
  }
}
.name-rating {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  /* width:185px; */
  width: fit-content;
}
.name {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  color: #333333;
  font-family: "Montserrat", sans-serif;
}
.reviews {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-left: 5px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  gap: 0px;
  border-radius: 50%;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #e6e6e6;
  background-image: url("../images/nofaceuser.png");
}

.ratingstars {
  display: flex;
  justify-content: space-around;
}
.score {
  color: gold;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}
.stars {
  display: flex;
  margin-left: 5px;
}
.profile-preview {
  display: flex;
}

.message {
  background: #ffffff;
  width: 160.98px;
  height: 40px;
  border-radius: 4px;

  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  padding-right: 25px;
  color: #994b00;

  box-shadow: 0px 0px 0px 1px #007562 inset;
  border: #007562;
}
@media screen and (max-width: 1200px) {
  .message {
    margin-top: 5px;
  }
}
.location {
  height: 33px;
  margin-top: 30px;
  display: flex;
  /* align-items:center; */
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .location {
    flex-direction: column;
    padding-top: 10px;
    justify-content: space-around;

    height: auto;
  }
}
.years {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #666666;
  display: flex;
  justify-content: center;
  width: 164px;
  align-items: center;
  background-color: #e6e6e6;
  height: 28px;
  width: 164px;
  gap: 7px;
  padding-left: 4px;
}
.address {
  width: 170px;
  height: 33px;
  opacity: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  /* line-height: 20px; */
  color: #666666;
  display: flex;
  /* justify-content:space-between; */
  padding-left: 12px;
  box-sizing: border-box;
}
@media screen and (max-width: 1200px) {
  .address {
    margin-top: 5px;
  }
}
.about {
  height: fit-content;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  color: #666666;
}

.about a {
  text-decoration: none;
}
.buttons {
  max-width: 240px;
  width: 100%;
  max-height: 53px;
  border-radius: 22px;
  background-color: #ece2dc;
  color: #67382133;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .buttons {
    height: 40px;
  }
}

.pre-nxt {
  max-width: 897px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 90px;
}
.btn1,
.btn2,
.btn3,
.btn4 {
  max-width: 62px;
  width: 100%;
  height: 53px;
  border-radius: 22px;
  color: #673821;
  border: none;
  background-color: #ece2dc;
}
@media screen and (max-width: 768px) {
  .btn1,
  .btn2,
  .btn3,
  .btn4 {
    height: 40px;
  }
}
.btn1 {
  background-color: #673821;
  color: #ffffff;
}
.nxtbtn {
  max-width: 180px;
  width: 100%;
  max-height: 53px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 40px;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}
.prebtn {
  width: 100%;
  max-width: 180px;
  height: 53px;
  background-color: #ffffff;
  border-radius: 40px;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 768px) {
  .prebtn,
  .nxtbtn {
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
  }
}

/* article list css */

.newArticleListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0rem;
  padding-left: 40px;
  padding-right: 40px;
}
.newArticleContent {
  width: 200px;
  height: 44px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  word-wrap: break-word;
}
.newArticleListHeaderTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.newArticleListHeaderText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
/* .newArticleCardListContainer {
  margin: 20px 0px;
  width: 352px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0 0.25rem;
} */

.newArticleCardListContainer {
  margin: 20px 0px;
  width: 100%; /* Adjusted for responsiveness */
  display: flex;
  flex-direction: column;
  gap: 20px; /* Changed from row-gap to gap */
  padding: 0 0.25rem;
}

.newArticleCard {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  cursor: pointer;
}
.newArticleImg {
  width: 90px;
  height: 90px;
}
.btnPrimary {
  width: 100%;
  background: linear-gradient(90deg, #a77041 0%, #673821 100%);
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 10px !important;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #ffffff;
}
/* New Article List ends */
.articleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0rem;
  width: 100%;
  padding: 3% 2%;
}
.articleCardContainer {
  width: 100%;
}
.articleCard {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 5%;
  height: 225px;
  background: #ffffff;
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.articleCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.articleFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.articleCardImgContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.nameContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #262626;
}
.timeContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #727272;
}
.articleContent {
  /* width: 700px; */
  height: 64px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.likesCount {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.commentsCount {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.shareCount {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.footerImgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  background-color: #f5f5f5;
  border-radius: 50%;
  border: 3px solid #fff;
  padding: 0.5rem;
  overflow: hidden;
}
.paginationBtn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 180px;
  height: 53px;
  border: 1px solid #000000;
  border-radius: 40px;
}
.paginationBtnText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
}
.paginationPageBtnContainer {
  background: rgba(103, 56, 33, 0.2);
  border-radius: 22px;
}
.paginationPageBtn {
  width: 62px;
  height: 53px;
  /* background: #673821; */
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active {
  background: #673821;
  color: #fff;
}

@media (min-width: 320px) and (max-width: 990px) {
  .newArticleListContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 320px) and (max-width: 460px) {
  .articleFooter {
    flex-direction: column;
    /* justify-self:flex-start; */
    align-items: flex-start;
  }
  .likesContainer {
    /* padding-bottom: 15px; */
  }
  .paginationBtn {
    /* width: 45px !important; */
    width: 100% !important;
    height: 30px !important;
    font-size: 0.8rem;
    padding: 0 0.5rem;
  }
  .paginationContainer {
    padding: 0 !important;
    width: 100% !important;
  }
  .paginationPageBtn {
    width: 30px;
    height: 30px;
  }
  .paginationBtnIcon {
    width: 20px;
    height: 20px;
  }
  .paginationBtnText {
    font-size: 0.8rem;
  }
  .newArticleCard {
    padding: 0 0;
  }
  .articleCardHeader {
    justify-content: space-between;
  }
  .articleCard {
    padding: 0.25rem;
  }
  .newArticleListContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
  .paginationPageBtn {
    width: 35px;
  }
  .newArticleCard {
    justify-content: space-between !important;
  }
  .newArticleListHeaderText {
    font-size: 1.2rem;
  }
  .newArticleImg {
    width: 70px;
    height: 70px;
    object-fit: contain;
  }
  .newArticleContent {
    font-size: 1rem;
    width: 100%;
  }
  .btnPrimary {
    font-size: 1rem;
  }
  .articleContainer {
    padding: 0 !important;
  }
  .nameContainer {
    font-size: 1rem;
  }
  .timeContainer {
    font-size: 0.575rem;
  }
  .articleContent {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .articleListContainer {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
  .paginationBtn {
    width: 100%;
    margin: auto;
  }

  .newArticleListContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
  .paginationPageBtn {
    width: 30px;
  }
  .newArticleCard {
    justify-content: space-around;
  }
}

/* create post css */

.newPostContainer {
  /* Group 1000002986 */

  width: 790px;
  height: 465px;
  padding: 20px;
}
.newPostContainerHeader {
  display: flex;
  justify-content: space-between;
}
.newPostHeaderText {
  /* Create New Post */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #262626;
}
.postContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  padding: 1rem;
}
.postTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical too box height */
  margin: 1rem 0 2rem 0;
  color: #727272;
}
.postTextContainer {
  border: none;
  width: 100%;
  height: 200px;
  color: #727272;
  margin-bottom: 2rem;
}
.postFooterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.postFooterIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}
.postFooterIcon {
  width: 20px;
  height: 20px;
  color: #000;
}
.postButton {
  width: 110px;
  height: 40px;
  background: linear-gradient(180deg, #a77041 0%, #673821 100%);
  border-radius: 49px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
}
@media (min-width: 320px) and (max-width: 990px) {
}

@media (min-width: 320px) and (max-width: 768px) {
  .newPostContainer {
    padding: 15px 0px 0px 0px;
  }
}
@media (min-width: 320px) and (max-width: 420px) {
  .CreatePostContainer {
    flex-direction: column;
  }
  .newPostContainer {
    width: 100%;
  }
}

/* view post css */

.navbarWrapper {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 57px; */
  /* padding-top: 42px; */
  width: 100%;
  top: 47px;
}
.navbarContainer {
  padding: 0.5rem 1.25rem;
  background-color: #fff;
  border-radius: 4px;
  height: 57px;
  width: 1260px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 157px;
  height: 57px;
}
.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}
.searchBoxContainer {
  width: 315px;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #c0c0c0;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  column-gap: 10px;
  padding-left: 10px;
}
.searchBox {
  /* width: 100%;
    height: 100%; */
  border: none;
  outline: none;
  font-size: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #000;
}
.searchIcon {
  font-size: 20px;
}
.searchBox::placeholder {
  color: #c0c0c0;
}
.profileContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}
.profileName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #c0c0c0;
}
/* .headerText {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #ffffff;
    margin-top: 100px;
    cursor: pointer;
  }
  .subHeaderText {
    margin-top: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    width: 995px;
    color: #ffffff;
    cursor: pointer;
  } */

.viewPostContainer {
}
.postDetailsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding: 2rem;
}
.profileImgContainer {
  width: 40px;
  height: 40px;
}
.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.postContentContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.postTitle {
  width: 527px;
  height: 29px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #333333;
}
.profileDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nameContainer {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #333333;
  padding-right: 5px;
}
.timeContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #8f8f8f;
  border-left: 1px solid #8f8f8f;
  padding-left: 5px;
}
.postTextContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #333333;
}
.footerContainer {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.commentsListContainer {
  margin-top: 3rem;
  padding: 2rem;
}
.commentListheader {
  width: 172px;
  height: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333333;
}
.commentsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  margin: 1.5rem 0;
}
.commentsProfileContainer {
  width: 40px;
  height: 40px;
}
.commentsProfileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.commentContentContainer {
  display: flex;
  flex-direction: column;
  /* row-gap: 1rem; */
}
.commentHeader {
  width: 275px;
  height: 17px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13.2344px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #333333;
}
.commentSubText {
  width: 99px;
  height: 17px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13.2344px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #333333;
}
.commentTimeContainer {
  width: 76px;
  height: 15px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #666666;
}
.commentTextContainer {
  /* width: 599px;
  height: 101px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #333333;
}
.commentFooterContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}
.viewProfileBtn {
  background: rgba(153, 75, 0, 0.2);
  border-radius: 41px;
  border: none;
  padding: 0.5rem 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #994b00;
}
.newCommentContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  margin: 1rem 0;
}
.newCommentProfileContainer {
  width: 40px;
  height: 40px;
}
.newCommentProfileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.newCommentInput {
  width: 599px;
  height: 101px;
  /* border: 1px solid #c0c0c0; */
  border: none;
  border-radius: 8px;
  padding: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
}
.newCommentFooterContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}
.postCommentBtn {
  width: 122px;
  height: 40px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  background: #a77041;
  border: none;
  border-radius: 49px;
}

@media (min-width: 320px) and (max-width: 990px) {
  .viewPostContainer {
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .viewPostContainer {
    flex-direction: column;
  }
  .newCommentFooterContainer {
    justify-content: flex-start;
  }
  .postDetailsContainer {
    flex-direction: column;
  }
  .newCommentInput {
    width: 570px;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .viewPostContainer {
    flex-direction: column;
  }
  .newCommentFooterContainer {
    justify-content: flex-start;
  }
  .postDetailsContainer {
    flex-direction: row;
    padding: 0rem;
  }
  .postContentContainer {
    /* padding: 0.25rem; */
    row-gap: 0.25rem;
  }
  .footerContainer {
    margin-top: 0.5rem;
  }
  .postTitle {
    /* width: 100%;
    height: 100%; */
    font-family: "Montserrat";
    font-size: 1rem;
    width: unset;
    height: unset;
  }
  .newCommentInput {
    width: 270px;
  }
}

/*--------------------------------------------------------------------*/
.header_top .navbar-expand-lg .navbar-nav .nav-link {
  position: relative;
  padding: 5px 20px;
  color: #2e2e2e;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.header_top .navbar-expand-lg .navbar-nav .nav-link.active,
.header_top .navbar-expand-lg .navbar-nav .nav-link:hover {
  font-weight: 600;
}
.navbar-expand-lg .navbar-nav .dropdown .btn {
  color: #000;
  font-size: 20px;
  padding: 5px 24px;
  font-weight: 400;
  border: 0;
  z-index: 9;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: end;
  gap: 80px;
}
.navbar-expand-lg .navbar-collapse .btn_grp {
  display: flex;
  align-items: center;
}
.btn_grp .search_bar {
  position: relative;
}
.btn_grp .search_bar .form-control {
  color: #000;
  font-size: 16px;
  font-weight: 400; /* 143.75% */
  border-radius: 8px;
  border: 1px solid #c0c0c0;
  box-shadow: 10px 24px 54px 0px rgba(0, 0, 0, 0.06);
  max-width: 100%;
  width: 350px;
  padding-left: 45px;
}
@media only screen and (max-width: 1400px) {
  .btn_grp .search_bar .form-control {
    width: 100%;
    max-width: none;
  }
}
.btn_grp .search_bar .icon {
  position: absolute;
  top: 3px;
  left: 10px;
}
.btn_grp .user_login {
  margin-left: 30px;
}
.user_login .btn {
  background: transparent;
  padding: 0;
  border: 0;
  color: #c0c0c0;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.user_login .dropdown-menu {
  padding: 0;
  background: #fff;
  border-radius: 0;
  border: 0;
  width: 215px;
  right: 0;
  position: absolute;
  left: initial;
}
.user_login .dropdown-menu li {
  z-index: -1;
}
.user_login .dropdown-menu a {
  padding: 13px 30px;
  color: #a77041;
  font-size: 14px;
  font-weight: 600;
  border-left: 4px solid transparent;
  line-height: normal;
  border-bottom: 1px solid #bec1c4;
  border: 0;
}
.user_login .dropdown-menu a.active,
.user_login .dropdown-menu a:hover {
  color: #fff;
  background: #a77041;
}
.navbar-nav .dropdown .dropdown-menu {
  border: 0;
  background: #f2f2f2;
  box-shadow: 0px 0px 20px rgba(190, 190, 190, 0.2);
  width: 300px;
  padding: 0;
  border-radius: 4px;
}
.navbar-nav .dropdown .dropdown-menu a {
  color: #000;
  font-size: 16px;
  padding: 11px 15px;
  font-weight: 500;
}

.navbar-expand-lg {
  padding: 12px 0;
}
a.navbar-brand {
  padding: 0;
  margin: 0;
}
.navbar-brand img {
  width: 120;
}
.navbar-nav .dropdown .dropdown-menu a:hover {
  background: #a77041;
  color: #fff;
}
.user_login .btn img {
  width: 52px;
  height: 52px;
  border-radius: 12px;
}
.bg_brd .user_login .btn span {
  color: #fff;
}
.user_login .btn span:first-child {
  margin-right: 10px;
}

/*-------------------------------------------------------------------------*/
.inr_bnnr_section {
  z-index: 0;
  position: relative;
  min-height: 500px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inr_bnnr_section .media_img,
.inr_bnnr_section .media_video {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: -1;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  width: 100%;
  height: 100%;
}
.user_profile {
  margin-top: -15%;
  z-index: 2;
  position: relative;
}
.profile_cover {
  background: #fff;
  box-shadow: 0px 11.2px 30px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 30px 60px;
}
.profile_cover .cont_prf {
  margin-left: 35px;
}
.profile_cover .cont_prf h3 {
  color: #673821;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.profile_cover .cont_prf h4 {
  color: #2c3c4d;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 10px;
}
.profile_cover .cont_prf p {
  color: #8c959f;
  font-size: 24px;
  font-weight: 500;
}
.profile_cover .cont_prf .Review_btn {
  margin-top: 20px;
}
.profile_cover .cont_prf .Review_btn .btn {
  color: #673821;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #673821;
  font-weight: 500;
  margin-right: 15px;
  padding: 8px 15px;
  min-width: 120px;
}
.profile_cover .cont_prf .Review_btn .btn span {
}
.profile_cover .cont_prf .btn_grp {
  margin-top: 30px;
}
.profile_cover .cont_prf .btn_grp .btn {
  border-radius: 500px;
  background: linear-gradient(129deg, #a77041 22.82%, #673821 58.7%);
  color: #fff;
  font-size: 18px;
  padding: 8px 24px;
  min-width: 250px;
  margin-right: 20px;
}
.profile_cover .user_img {
  width: 351px;
  height: 415px;
  display: flex;
  align-items: center;
  margin-top: -12%;
}
.profile_cover .user_img img {
  width: 100%;
}
.rating {
}
.rating > label {
  color: rgba(196, 196, 196, 1);
  float: right;
}
.rating > label:before {
  margin: 5px;
  font-size: 2em;
  font-family: FontAwesome;
  content: "\f005";
  display: inline-block;
}
.rating > input {
  display: none;
}
.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: rgba(255, 135, 35, 1);
}
.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  color: #fece31;
}
.card_bx {
  border: 1px solid #e3e3e3;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  margin-bottom: 20px;
}
.card_bx {
  border: 1px solid #e3e3e3;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  margin-bottom: 20px;
}
.card_bx h3 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 34px;
  text-transform: uppercase;
}
.rating_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.About_wrapp p {
  color: #8c959f;
  font-size: 20px;
  line-height: 30px;
}
.Portfolio_wrapp {
}
.Portfolio_wrapp ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0 -15px;
}
.Portfolio_wrapp ul li {
  position: relative;
  display: block;
  border-radius: 42px;
  background: #c4c4c4;
  margin: 0 15px;
  overflow: hidden;
}
.Portfolio_wrapp ul li img {
  width: 100%;
}
.Portfolio_wrapp ul li:after {
  position: absolute;
  content: "";
  background-image: linear-gradient(
    359deg,
    #000 -45.48%,
    rgba(0, 0, 0, 0.61) 34.08%,
    rgba(255, 252, 252, 0) 103.14%
  );
  width: 100%;
  height: 100%;
  left: 0;
}
.Portfolio_wrapp ul li h3 {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  z-index: 2;
  position: absolute;
  bottom: 20px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}
.tab_prof ul li a {
  border: 1px solid #e3e3e3;
  background: #fff;
  color: #8c959f;
  display: block;
  width: 184px;
  text-align: center;
  padding: 15px 20px;
  font-size: 18px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-weight: 500;
  text-transform: uppercase;
}
.tab_prof ul li {
  display: inline-block;
  padding: 0 10px;
}
.tab_prof ul {
  margin: 0 -10px;
}
.tab_prof ul li.active a {
  background: #673821;
  color: #fff;
}
.tab_prof {
  padding: 40px 0;
}
footer.footer {
  margin-top: 100px;
  background: #673821;
  color: #fff;
  padding: 60px 0 15px;
}
footer.footer .footer_bx {
}
footer.footer .footer_bx img {
  margin-bottom: 20px;
}
footer.footer .footer_bx ul {
}
footer.footer .footer_bx ul li {
  display: block;
  padding-bottom: 15px;
}
footer.footer .footer_bx ul li a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: start;
}
footer.footer .footer_bx ul li a span {
}
footer.footer .footer_bx ul li a span:first-child {
  margin-right: 10px;
}
footer.footer .footer_bx .heading_ft {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
}
footer.footer .footer_bx .ft_soical {
  margin: 0;
}
footer.footer .footer_bx .ft_soical li {
  display: inline-block;
  margin: 0;
  padding: 0 5px;
}
footer.footer .footer_bx .ft_soical li a {
}
.footer_btm {
  background: #fff;
  margin-top: 30px;
}
.footer_btm p {
  color: #673821;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  padding: 4px 0;
}
.portfolio_details .big_prof {
  display: block;
}
.portfolio_details .big_prof {
  border-radius: 12px;
}
.portfolio_details .protf_thumb {
  grid-template-columns: repeat(2, 1fr);
  margin: -15px;
  display: grid;
}

.portfolio_details .protf_thumb li a img,
.portfolio_details .big_prof img {
  width: 100%;
}
.portfolio_details .protf_thumb li {
  margin: 15px;
  position: relative;
  display: block;
}
.portfolio_details .protf_thumb li a,
.portfolio_details .big_prof a {
  position: relative;
  border-radius: 12px;
  background: #c4c4c4;
  overflow: hidden;
  display: block;
}
.portfolio_details .big_prof h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
  z-index: 2;
  position: absolute;
  bottom: 20px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}
.portfolio_details .protf_thumb li h3 {
  z-index: 2;
  position: absolute;
  bottom: 20px;
  margin: 0;
  left: 20px;
  color: #fff;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 150%;
}
.portfolio_details ul.protf_thumb li a:after,
.portfolio_details .big_prof a::after {
  position: absolute;
  content: "";
  background-image: linear-gradient(
    359deg,
    #000 -45.48%,
    rgba(0, 0, 0, 0.61) 34.08%,
    rgba(255, 252, 252, 0) 103.14%
  );
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
}
.portfolio_details .protf_thumb li a {
  border-radius: 12px;
  display: grid;
  grid-auto-rows: 197px;
}
.custom_modal {
}

.custom_modal .modal-dialog {
  max-width: 700px;
}

.custom_modal .modal-dialog .modal-content {
  background: #fff;
  border-radius: 0;
  box-shadow: 10px 24px 54px 0px rgba(0, 0, 0, 0.06);
}

.custom_modal .modal-dialog .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 1;
  background: transparent;
  border: 0;
  box-shadow: none;
}

.custom_modal .modal-dialog .connect_popup {
  padding: 15px;
}

.custom_modal .modal-dialog .connect_popup > h3 {
  color: #141414;
  font-size: 36px;
  font-weight: 600;
}

.custom_modal .modal-dialog .connect_popup > p {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.avtar {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.avtar .user_img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  overflow: hidden;
}

.avtar .user_img img {
  width: 100%;
}

.avtar .cont_bx {
  margin-left: 10px;
}

.avtar .cont_bx h3 {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.avtar .cont_bx p {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  line-height: 22px;
}

.custom_modal .modal-dialog .connect_popup .frm_grp_connect {
}

.custom_modal
  .modal-dialog
  .connect_popup
  .frm_grp_connect
  textarea.form-control {
  min-height: 200px;
  border: 0;
  border-radius: 8px;
  background: #f6f6f6;
}

.btn_grp_com {
  text-align: right;
  margin-top: 30px;
}

.btn_grp_com .btn {
  min-width: 120px;
  color: #673821;
  font-size: 18px;
  padding: 5px 12px;
  border-radius: 49px;
  border: 1px solid #984b00;
  font-weight: 400;
  text-transform: uppercase;
}

.btn_grp_com .btn + .btn,
.bg_btn {
  margin-left: 15px;
  border-radius: 49px;
  background: linear-gradient(180deg, #a77041 0%, #673821 100%);
  color: #fff;
}

.quot_frm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 20px;
}

.quot_frm label {
  color: #464646;
  font-size: 14px;
  font-weight: 500;
}

.form-control {
  color: #a3a3a3;
  border-radius: 5px;
  border: 1px solid #ebedf4;
  background: #fff;
  font-size: 16px;
  font-weight: 500;
  height: 45px;
  line-height: 23px;
  text-decoration: none;
}

.frm_grp {
  margin-bottom: 20px;
  position: relative;
}

.frm_grp .frm_upload {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ebedf4;
  background: #fff;
  justify-content: space-between;
  padding: 4px 15px;
  height: 45px;
}
.frm_grp .frm_upload a {
  color: #a3a3a3;
  font-size: 16px;
  font-weight: 400;
}
.frm_grp .frm_upload .upload_bx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.frm_grp .frm_upload .upload_bx .form-control {
  display: none;
}
.quot_frm label.Verified_btn {
  border-radius: 5px;
  border: 1px solid #167cda;
  padding: 4px 24px;
  margin: 0;
  margin-right: -6px;
  color: #249aef;
  font-size: 10px;
  line-height: 22px;
}
.connect_popup.send_popup {
  text-align: center;
}
.connect_popup.send_popup img {
  margin-bottom: 30px;
}
.connect_popup.send_popup h4 {
  color: #333;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.connect_popup.send_popup .btn_grp_com {
  text-align: center;
}
.bg_btn {
  color: #fff !important;
}
header.header_top {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  background: #fff;
}
.frm_grp.arrow_select {
  position: relative;
}
.frm_grp.arrow_select:after {
  content: "";
  position: absolute;
  right: 15px;
  z-index: 9;
  top: 46px;
  pointer-events: none;
  background: url(../images/ic_down.png);
  width: 20px;
  height: 20px;
}
.frm_grp .Manuf_notif {
  color: #404040;
  font-size: 8px;
  font-weight: 500;
  position: absolute;
  top: 0;
  text-transform: capitalize;
  right: 0;
}

.Subscrip_sect {
  padding-top: 50px;
  margin-bottom: 50px;
  background: #fff;
}

.mb-100 {
  margin-bottom: 100px;
}
.invdi_sect .box_plan {
  padding: 35px 100px 100px;
  border-radius: 8px;
}

.box_plan {
  background: rgba(255, 255, 255, 1);
  fill: #fff;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}
.box_plan h3 {
  margin: 0;
  color: #333;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;
}
.box_plan p {
}
.credit_card_bx ul {
  margin: 0;
  padding: 0;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px;
  margin-bottom: 30px;
}
.credit_card_bx ul li {
  gap: 15px;
  display: flex;
  letter-spacing: 0.005em;
  height: 80px;
  align-items: center;
  border-radius: 4px;
  background: #d3d3d3;
  justify-content: center;
  margin-top: 50px;
  color: #a5a5a5;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}
.credit_card_bx ul li span {
  font-size: 22px;
}
.credit_card_bx ul li:nth-child(2) {
  border-radius: 5px;
  border: 1px solid #804916;
  background: #fff;
  color: #676767;
}
.credit_card_bx ul li:nth-child(2) span {
  color: #804916;
}
.credit_card_bx h3 {
  margin-bottom: 10px;
  color: #333;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.credit_card_bx .frm_grp {
  position: relative;
  margin-bottom: 20px;
}
.credit_card_bx .frm_grp .icon_bx img:first-child {
  margin-right: 10px;
}
.credit_card_bx .frm_grp .form-control {
}
.credit_card_bx .frm_grp label {
  color: #676767;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
}
.credit_card_bx .frm_grp .icon_bx {
  position: absolute;
  right: 20px;
  top: 40px;
}
.credit_card_bx .grp_sect {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.credit_card_bx .grp_sect .rgt_bx {
  display: flex;
  align-items: center;
}
.credit_card_bx .grp_sect .rgt_bx .form-control {
  width: 90px;
  text-align: center;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
.credit_card_bx .grp_sect .rgt_bx span {
  width: 20px;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.credit_card_bx .btn_grp {
  text-align: center;
  margin-top: 50px;
}
.Subscrip_sect .btn_grp .btn {
  border-radius: 0;
  width: 70%;
  height: 50px;
  border: 0;
  background: linear-gradient(160deg, #0068c4 0%, #00a7c5 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.98px;
  text-transform: uppercase;
}
.paym_card {
  padding: 20px;
  margin-top: 300px;
  border-radius: 5px;
  background: rgba(103, 56, 33, 0.5);
}
.crd_img_wif {
  margin-bottom: 60px;
}
.crd_details h3 {
  margin-bottom: 15px;
  color: #333;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px; /* 160% */
  letter-spacing: 1.6px;
  text-transform: capitalize;
}
.crd_details p {
  font-weight: 500;
  font-size: 20px;
  color: #5c5050;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin: 0;
}
.crd_det {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 30px;
}
.crd_det h3 {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px; /* 160% */
  letter-spacing: 1.6px;
  text-transform: capitalize;
}
.crd_name {
  text-align: right;
}
.crd_name p {
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  color: #242424;
}
.paym_card p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.box_plan p {
  text-align: center;
  color: #676767;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
.paym_card p span:first-child {
  text-transform: capitalize;
  color: #5c5050;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}
.paym_card p span:last-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}
.pay__bx {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin: 0px -20px;
  padding: 15px 15px 0;
  border-top: 1px dashed #dedede;
  position: relative;
  margin-top: 30px;
}
.pay__bx:after {
  width: 50px;
  height: 38.29px;
  background: #fff;
  position: absolute;
  content: "";
  left: -30px;
  top: -21px;
  border-radius: 20px;
}
.pay__bx:before {
  width: 50px;
  height: 38.29px;
  background: #fff;
  position: absolute;
  content: "";
  right: -30px;
  top: -21px;
  border-radius: 20px;
}
.pay__bx p {
  margin: 0;
}
.paym_card .pay__bx span:last-child {
  font-size: 24px;
  font-weight: 600;
  color: #673821;
}
.paym_card p span.price {
  color: #0068c4;
}
.paym_card p span:first-child {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-transform: capitalize;
}

.credit_card_bx .frm_grp .form-control {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  height: 50px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
}

.crd_details p {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-transform: capitalize;
}

.crd_name p {
  color: #242424;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-transform: capitalize;
}

.paym_card p {
  justify-content: center;
}

.paym_card p span.price {
  color: #0068c4;
}
.crd_details {
  margin-top: -170px;
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.credit_card_bx .grp_sect .lft_bx {
}

.credit_card_bx .grp_sect .lft_bx label {
  color: #676767;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.crd_details p {
  justify-content: center;
}
.credit_card_bx .grp_sect .lft_bx h3 {
  margin-bottom: 0;
}
.paym_card p.text {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.Subscrip_sect .btn_grp_com {
  text-align: center;
}

.Subscrip_sect .btn_grp_com .btn {
  padding: 5px 24px;
  text-transform: initial;
}
.signup_page {
  position: relative;
  min-height: 100vh;
}
.bg_inner {
  position: relative;
}
.bg_inner .media_img {
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: -1;
  object-fit: cover;
  max-width: 100%;
  object-position: top;
  max-height: 100%;
  margin: auto;
  width: 100%;
  height: 100vh;
}
.card_success {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: calc(100vh - 0px);
  z-index: 2;
}
.card_success .bg_inner {
  width: 567px;
  border-radius: 40px;
  border: 2px solid #fff;
  background: linear-gradient(
    146deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(10px);
  text-align: center;
  padding: 60px 40px;
}

header.header_top.succes_hd {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}

.card_success .bg_inner img {
  width: 150px;
}

.card_success .bg_inner h3 {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.card_success .bg_inner p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;

  text-transform: uppercase;
  margin: 0;
}
.search_bar {
  display: flex;
  align-items: center;
}

.search_bar {
  position: relative;
}
.search_bar .form-control {
  border-radius: 5px;
  border: 1px solid var(--Outline, #e2e2e2);
  background: #fff;
}
.search_bar span {
  position: absolute;
  right: 15px;
  top: 9px;
}

.search_bar label {
  color: #565656;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
}
.timeline_track {
  display: flex;
  align-items: center;
}
.timeline_track {
}
.timeline_track .qr_track {
  width: 60%;
  text-align: center;
}
.timeline_track .qr_track .qr_bar {
  border: 2px solid #000;
  width: 138px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 135px;
  overflow: hidden;
  margin: 0 auto;
}
.timeline_track .qr_track .qr_bar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.timeline_track .qr_track .btn_comm {
  display: block;
  text-align: center;
  margin: 0 auto;
}
.timeline_track .qr_track .btn_comm .btn {
  display: block;
  border-radius: 8px;
  background: linear-gradient(180deg, #c49c6e 1.53%, #804916 106.63%);
  border-color: #804916;
  color: #fff;
  margin: 20px auto;
}
.timeline_track .qr_track .btn_comm .btn + .btn {
  border-radius: 8px;
  border: 1px solid #212121;
  background: #fff;
  color: #212121;
  border-color: #212121;
}
.track_data button.btn_view {
  width: 150px;
}
.history-tl-container {
  display: block;
  position: relative;
  margin-top: 30px;
  text-align: center;
}
.history-tl-container ul.tl {
  padding: 0;
  display: inline-block;
  margin: 0;
}
.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  margin-left: 185px;
  min-height: 38px;
  border-left: 2px solid #dedede;
  padding: 0 0 36px 30px;
  position: relative;
  margin-left: 185px;
}
.history-tl-container ul.tl li:last-child {
  border-left: 0;
  padding-bottom: 0;
  min-height: auto;
}
.history-tl-container ul.tl li::before {
  position: absolute;
  left: -5px;
  top: 0;
  content: " ";
  border-radius: 100%;
  background: #dedede;
  height: 11px;
  width: 11px;
  transition: all 500ms ease-in-out;
}

ul.tl li .item-title {
  text-align: left;
  color: #828282;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: normal;
}
ul.tl li .timestamp {
  color: #828282;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  position: absolute;
  width: 100px;
  left: -120%;
  top: 0;
  text-align: right;
  line-height: normal;
}
.history-tl-container ul.tl li.active {
  border-color: #5bd366;
}

.history-tl-container ul.tl li.active:before {
  background: #5bd366;
  left: -6px;
}
.Invoice_wrapp {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  padding: 30px;
  margin-left: 15%;
  text-align: center;
}

.Invoice_wrapp h3 {
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.Invoice_wrapp p {
  color: #212121;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
}

.Invoice_wrapp .btn {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  border-radius: 5px;
  width: 100%;
  background: #673821 !important;
  line-height: normal;
  padding: 12px 24px;
  margin-bottom: 20px;
}

.Invoice_wrapp h4 {
  color: var(--Black, #212121);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.Invoice_wrapp h4 span:first-child {
}

.Invoice_wrapp h4 span:last-child {
  color: #565656;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.messages-list .msg_crd {
  background: rgba(103, 56, 33, 1);
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.messages_card {
  border-radius: 5px;
  background: #fff;
  padding: 20px;
}
.msg_crd h3 {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin: 0;
}
.msg_crd p {
  color: #fff;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 0;
  line-height: normal;
  text-align: center;
}

.msg_crd p span {
  width: 32px;
  height: 32px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.chat-body {
  padding: 0 15px;
}

.chat-inner {
  position: relative;
  text-align: center;
  /* overflow-y: scroll; */
  /* padding-right: 20px; */
  /* margin-right: -15px; */
}
.chat-mss ul li p {
  position: relative;
  top: 10px;
  display: table;
  margin-left: 30px;
  padding: 12px;
  margin-bottom: 4px;
  padding-right: 80px;
  position: relative;
  margin-top: 10px;
  white-space: pre-line;
  letter-spacing: 0.02em;
  text-align: left;
  box-shadow: 0px 3px 20px rgb(0 0 0 / 5%);
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  font-family: "Montserrat";
  border-radius: 8px 8px 8px 0px;
  background: #673821;
}
.chat-mss.rply-mss ul li p span.msgSpan {
}
.chat-mss ul li {
  list-style: none;
}
.chat-mss {
  margin-bottom: 20px;
}

.chat-mss.rply-mss ul li p span {
  background: #306dd0;
  color: #fff;
  top: 0;
}
.chat-mss.rply-mss ul li {
  float: right;
  direction: rtl;
}
.chat-mss ul li {
  list-style: none;
}
.chat-mss.rply-mss {
  display: inline-block;
  width: 100%;
}
.chat-mss ul li span img {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.06em;
  flex-shrink: 0;
  float: left;
  position: relative;
  top: 40px;
}
.chat-body {
  height: 70vh;
}
.sent-btn-emoji ul {
  margin: 0;
}
.chat-inner {
  position: relative;
  text-align: center;
  height: calc(70vh - 85px) !important;
  overflow-y: scroll;
  padding-right: 20px;
  margin-right: -15px;
}
.chat-mss.rply-mss ul li p {
  top: 10px;
  position: relative;
  right: 0;
  text-align: left;
  direction: ltr;
  border-radius: 8px 8px 0px 8px;
  background: #673821;
  margin-right: 30px;
}

.chat_msg_cont {
  position: relative;
}

.chat_msg_cont {
  position: relative;
}

.chat_sect_bx .chat-mss.rply-mss ul li p {
  padding-right: 80px;
  position: relative;
}
.chat_sect_bx .chat-mss ul li p .time_check {
  position: absolute;
  bottom: 4px;
  right: 3px;
  display: inline-block;
  top: initial;
}
.chat_sect_bx .chat-mss.rply-mss ul li p .time_check {
  position: absolute;
  bottom: 4px;
  right: 3px;
  display: inline-block;
  top: initial;
}

.chat-mss ul li p .time_check {
  position: absolute;
  bottom: 4px;
  right: 3px;
  display: inline-block;
  top: initial;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.chat-foot {
  display: grid;
  width: 100%;
  height: initial !important;
  min-height: 35px;
  align-items: center;
  position: absolute;
  bottom: 0px;
  padding: 4px 12px;
  left: 0;
  grid-template-columns: 20px 1fr 60px;
  background: rgba(0, 0, 0, 0.04);
  background-blend-mode: luminosity;
  backdrop-filter: blur(30px);
}
.sent-btn-emoji {
  margin-left: 0;
  right: 10px;
  z-index: 1;
}
.sent-btn-emoji ul li:nth-child(2) {
  color: #306dd0;
  text-align: center;
  width: 30px;
  height: 30px;
}
.sent-btn-emoji ul li:nth-child(2) a {
  display: inline-block;
  text-align: center;
}
.sent-btn-emoji ul li {
  list-style: none;
  display: inline-block;
  font-size: 20px;
}
.msg_int_style {
  background-color: transparent;
}
.att_plane label.btn {
  border: 0;
}
.att_plane input[type="file" i] {
  display: none;
}
.msg_int_style {
  border: 0 none white;
  outline: none;
  max-height: 32px;
  resize: none;
  width: 100%;
  padding: 0 20px;
  margin-top: 5px;
  padding-right: 15px;
  color: rgba(0, 0, 0, 0.61);
  font-size: 14px;

  /* 142.857% */
}
.chat_msg_cont {
  position: relative;
}

.chat_footer .chat-foot {
  width: 98%;
  border-radius: 8px;
}

.bg_inner.bg_account {
  width: 100%;
  max-width: 80%;
}

.bg_inner.bg_account h5 {
  color: #fff;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px; /* 170% */
  margin-bottom: 30px;
}

.bg_inner.bg_account .list_account {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg_inner.bg_account .list_account label {
  margin: 0 15px;
}

.bg_inner.bg_account .list_account label input {
  display: none;
}

.bg_inner.bg_account .list_account label span .card_acc {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  display: inline-block;
  padding: 15px;
  width: 279px;
  height: 258px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
/* .btn_grp .user_login .btn {
  color: #fff;
} */
.bg_inner.bg_account .list_account label span img {
  width: 100%;
}
button:focus {
  outline: none;
  box-shadow: none;
}
header.header_top.bg_brd {
  background: #673821;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.bg_inner.bg_account .list_account label span p {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}
.inr_bnnr_section:after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 63.2%, #000 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.inr_bnnr_section .banner_content {
  position: relative;
  z-index: 2;
}

.inr_bnnr_section .banner_content h3 {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.inr_bnnr_section .banner_content p {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px; /* 120% */
}
.bg_inner.bg_account .list_account input:checked ~ span .card_acc {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 4px 25px 0px #fff;
}
