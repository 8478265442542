.pricing {
  width: 1235px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) 80px;
  box-sizing: border-box;
  gap: 65px 0;
  max-width: 100%;
}
.frequentlyAskedQuestions {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.underlineIcon {
  align-self: stretch;
  height: 8px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.fAQTitle {
  width: 635px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: 10px 0;
  max-width: 100%;
}
.answersToCommon,
.findQuickSolutions {
  margin: 0;
}
.answersToCommonContainer {
  align-self: stretch;
  height: 62px;
  position: relative;
  font-size: var(--text-button-size);
  color: var(--color-dimgray-100);
  text-align: center;
  display: inline-block;
  flex-shrink: 0;
}
.fAQTitleParent,
.frameParent,
.landingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.fAQTitleParent {
  width: 654px;
  gap: 30px 0;
  max-width: 100%;
}
.frameParent,
.landingPage {
  box-sizing: border-box;
}
.frameParent {
  width: 1116px;
  padding: 0 var(--padding-xl) 55px;
  gap: 35px 0;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-19xl);
  color: var(--color-black);
  font-family: var(--text-button);
}
.landingPage {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  padding: 0 0 var(--padding-3xl);
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .pricing {
    padding-bottom: var(--padding-33xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .frequentlyAskedQuestions {
    font-size: var(--font-size-11xl);
  }
}
@media screen and (max-width: 750px) {
  .frameParent,
  .pricing {
    gap: 65px 0;
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
  .frameParent {
    gap: 35px 0;
    padding-bottom: var(--padding-17xl);
  }
}
@media screen and (max-width: 450px) {
  .pricing {
    gap: 65px 0;
  }
  .frequentlyAskedQuestions {
    font-size: var(--font-size-4xl);
  }
  .answersToCommonContainer {
    font-size: var(--font-size-base);
  }
}
