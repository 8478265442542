.navbarWrapper {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 57px; */
  /* padding-top: 42px; */
  width: 100%;
  top: 47px;
}
.navbarContainer {
  padding: 0.5rem 1.25rem;
  background-color: #fff;
  border-radius: 4px;
  height: 57px;
  width: 1260px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 157px;
  height: 57px;
}
.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}
.searchBoxContainer {
  width: 315px;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #c0c0c0;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  column-gap: 10px;
  padding-left: 10px;
}
.searchBox {
  /* width: 100%;
    height: 100%; */
  border: none;
  outline: none;
  font-size: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #000;
}
.searchIcon {
  font-size: 20px;
}
.searchBox::placeholder {
  color: #c0c0c0;
}
.profileContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}
.profileName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #c0c0c0;
}
/* .headerText {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #ffffff;
    margin-top: 100px;
    cursor: pointer;
  }
  .subHeaderText {
    margin-top: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    width: 995px;
    color: #ffffff;
    cursor: pointer;
  } */

.viewPostContainer {
}
.postDetailsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding: 2rem;
}
.profileImgContainer {
  width: 40px;
  height: 40px;
}
.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.postContentContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.postTitle {
  width: 527px;
  height: 29px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #333333;
}
.profileDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nameContainer {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #333333;
  padding-right: 5px;
}
.timeContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #8f8f8f;
  border-left: 1px solid #8f8f8f;
  padding-left: 5px;
}
.postTextContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #333333;
}
.footerContainer {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.commentsListContainer {
  margin-top: 3rem;
  padding: 2rem;
}
.commentListheader {
  width: 172px;
  height: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333333;
}
.commentsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  margin: 1.5rem 0;
}
.commentsProfileContainer {
  width: 40px;
  height: 40px;
}
.commentsProfileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.commentContentContainer {
  display: flex;
  flex-direction: column;
  /* row-gap: 1rem; */
}
.commentHeader {
  width: 275px;
  height: 17px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13.2344px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #333333;
}
.commentSubText {
  width: 99px;
  height: 17px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13.2344px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #333333;
}
.commentTimeContainer {
  width: 76px;
  height: 15px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #666666;
}
.commentTextContainer {
  /* width: 599px;
  height: 101px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #333333;
}
.commentFooterContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}
.viewProfileBtn {
  background: rgba(153, 75, 0, 0.2);
  border-radius: 41px;
  border: none;
  padding: 0.5rem 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #994b00;
}
.newCommentContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  margin: 1rem 0;
}
.newCommentProfileContainer {
  width: 40px;
  height: 40px;
}
.newCommentProfileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.newCommentInput {
  width: 599px;
  height: 101px;
  /* border: 1px solid #c0c0c0; */
  border: none;
  border-radius: 8px;
  padding: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
}
.newCommentFooterContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}
.postCommentBtn {
  width: 122px;
  height: 40px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  background: #a77041;
  border: none;
  border-radius: 49px;
}

@media (min-width: 320px) and (max-width: 990px) {
  .viewPostContainer {
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .viewPostContainer {
    flex-direction: column;
  }
  .newCommentFooterContainer {
    justify-content: flex-start;
  }
  .postDetailsContainer {
    flex-direction: column;
  }
  .newCommentInput {
    width: 570px;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .viewPostContainer {
    flex-direction: column;
  }
  .newCommentFooterContainer {
    justify-content: flex-start;
  }
  .postDetailsContainer {
    flex-direction: row;
    padding: 0rem;
  }
  .postContentContainer{
    /* padding: 0.25rem; */
    row-gap: 0.25rem;
  }
  .footerContainer{
    margin-top: 0.5rem;
  }
  .postTitle {
    /* width: 100%;
    height: 100%; */
    font-family: "Montserrat";
    font-size: 1rem;
    width: unset;
    height: unset;
  }
  .newCommentInput {
    width: 270px;
  }
}
